import React from 'react'
import '../css/subcaption.css'

export default function SubCaption({children}) {
    return (
        <div className='sub-caption-wrapper'>
            <div className='container sub-caption-inner'>
                {children}
            </div>
        </div>
    )
}