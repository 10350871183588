import React from 'react'
import TopCaption from '../../components/TopCaption'
import SERVICES from '../services/ServicesTextContent'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
export default function ContentMarketing() {
    const questionObject = {
         first: {
                 question: 'How many buyer person do we need  when launching content marketing efforts ?',
                 answer: 'Essentially, all platform are good but not great for your brand. Choosing the right social media platform to advertise, your products and services depends on several factors: product or service type/category, user demographics and consumer preferences, marketing goals, and more'
             },
             second: {
                 question: 'What Type of Businesses Can Content Marketing Help?',
                 answer: 'Content marketing can help both small and large businesses across industries. As long as your target audience is in the online space.'
             },
             third: {
                 question: 'How can Content Marketing Benefit My Business?',
                 answer: 'Content marketing is versatile and based on your unique goals and content type, it could boost your brand in vast ways: creating increased brand awareness, enhancing customer loyalty,generating website traffic, driving sales. and more!'
             },
             fourth: {
                 question: 'Is Content Marketing the Same as Social Media Marketing?',
                 answer: 'Content marketing encompasses any type of content(textual and visual material) that are used to push the relevance of your brand on any digital platform, while social media marketing takes place strictly on platforms like Facebook and Instagram and is time-limited'
             },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
                services={SERVICES.ContentMarketing.TopCaption.services}
                service_name={SERVICES.ContentMarketing.TopCaption.service_name}
                service_bg_class={SERVICES.ContentMarketing.TopCaption.service_bg_class}
                subtitle={SERVICES.ContentMarketing.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.ContentMarketing.ServiceFeatures.content_title1}
                description1={SERVICES.ContentMarketing.ServiceFeatures.description1}
                img1={SERVICES.ContentMarketing.ServiceFeatures.img1}
                content_title2={SERVICES.ContentMarketing.ServiceFeatures.content_title2}
                description2={SERVICES.ContentMarketing.ServiceFeatures.description2}
                img2={SERVICES.ContentMarketing.ServiceFeatures.img2}
                content_title3={SERVICES.ContentMarketing.ServiceFeatures.content_title3}
                description3={SERVICES.ContentMarketing.ServiceFeatures.description3}
                img3={SERVICES.ContentMarketing.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}