import React, { useRef, useEffect } from 'react'
import { ReCaptcha } from 'react-recaptcha-google'
import '../css/consultation.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Consultation() {
    const captchaDemo = useRef()
    const siteKey = '6Ld2_8sZAAAAAEEMPLErw9XrwCL_kvpWYWUso8lk'

    function onLoadRecaptcha() {
        if (captchaDemo) {
            captchaDemo.current.reset();
            captchaDemo.current.execute();
        }
    }
    function verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!  
        console.log(recaptchaToken, "<= your recaptcha token")
    }
    useEffect(() => {
        if (captchaDemo) {
            console.log("started, just a second...")
            captchaDemo.current.reset();
            captchaDemo.current.execute();
        }
    // eslint-disable-next-line
    }, [])
    function handleSubmit(e) {
        e.preventDefault()
        window.location.replace('/thankyou')
    }
    return (
        <React.Fragment>
            <Header />
            <div className='consultation-wrapper'>
                <div className='container consultation-container'>
                    <div className='title'>
                        <h1>Get Free Consultation</h1>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form-group">
                            <p>Full Name</p>
                            <input type="text" minLength="2" id="name" name="name" placeholder="e.g. Adekunle Ciroma" required />
                        </div>
                        <div className="form-group">
                            <p>Email Address</p>
                            <input type="email" minLength="2" id="email" name="email" placeholder="e.g. ciroma@yopmail.com" required />
                        </div>
                        <div className="form-group">
                            <p>Phone Number</p>
                            <input type="text" minLength="2" id="phone" name="phone" placeholder="e.g. 08093786190" required />
                        </div>
                        <div className="form-group">
                            <p>Message</p>
                            <textarea type="text" minLength="2" id="message" name="message" placeholder="Tell us a bit bout your business" required />
                        </div>
                        <div style={{height:'0.1rem', visibility: 'hidden'}}>
                            <input type="hidden" id="consultation" name="consultation" value="Get Free Consultation(swiftbranding)" required readOnly/>
                        </div>
                        <ReCaptcha
                            ref={captchaDemo}
                            size="invisible"
                            render="explicit"
                            sitekey={siteKey}
                            onloadCallback={onLoadRecaptcha}
                            verifyCallback={verifyCallback}
                        />
                        <div className="form-group consultation-btn-wrapper">
                            <button type="submit" className="btn btn-primary" onClick={() => captchaDemo.current.execute()}>Send</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}