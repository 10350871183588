import React from 'react'
import '../css/topcaption.css'

export default function TopCaption({works, about, services, service_name, subtitle, service_bg_class}) {
    return (
        <React.Fragment>
            {works && <div className='works-caption'>
                <div className='container'>
                    <h2 className='title'>Our Works</h2>
                    <p className='subtitle'>Here is a look of few examples of different websites<br />we’ve created forstartups.</p>
                    <a href='/free-consultation'><button type='button'>Get Free Consultation</button></a>
                </div>
            </div>}
            {about && <div className='about-caption'>
                <div className='container about-caption-inner'>
                    <h2 className='title'>About Us</h2>
                    <p className='subtitle'>Swift Branding NG helps individuals and corporate entities create an apparent, articulate<br />and accurate brand identity that is easily identifiable to their markets,<br />targets and the public.</p>
                    <button className="btn nav-button" type="button" data-toggle='modal' data-target='#exampleModal'>
                        Request a Call
                    </button>
                </div>
            </div>}
            {services && <div className='services-caption'>
                <div className={`services-caption-inner ${service_bg_class}`}>
                    <h2 className='title'>{service_name.split('*')[0]}<br />{service_name.split('*')[1]}</h2>
                    <p className='lg'>{subtitle.split('*')[0]}<br />{subtitle.split('*')[1]}</p>
                    <p className='mb'>{subtitle.split('*')[0]} {subtitle.split('*')[1]}</p>
                    <div className='button-wrapper'>
                        <a href='/free-consultation'><button className='btn' type='button'>Get Free Consultation</button></a>
                    </div>
                    
                </div>
            </div>}
        </React.Fragment>
    )
}