import React from 'react'

import '../css/brands.css'

import weckautos from '../images/weckautos.svg'
import pro_seduction from '../images/pro_seduction.svg'
import johnson_furnitures from '../images/johnson_furnitures.svg'
import ts_getout from '../images/ts_getout.svg'
import bfu from '../images/bfu_brand_logo.png'
import avvila from '../images/avvila_brand_logo.png'
import opak from '../images/opak_brand_logo.png'
import studyAbroad from '../images/studyAbroad_brand_logo.png'
import UnderBar from './UnderBar'

export default function Brands({webdev, landing}) {
    return (
        <div className='brands-wrapper'>
            <div className='container brands-inner'>
                <div className='title'>
                    <h3>Brands we've worked with</h3>
                    <UnderBar bg_class='bg-orange' />
                </div>
                {landing && <div className='brands'>
                    <div className='brands-item'>
                        <img src={weckautos} alt='' />
                    </div>
                    <div className='brands-item'>
                        <img src={pro_seduction} alt='' />
                    </div>
                    <div className='brands-item'>   
                        <img src={johnson_furnitures} alt='' />
                    </div>
                    <div className='brands-item'>
                        <img src={avvila} alt='' />
                    </div>
                    <div className='brands-item'>
                        <img src={ts_getout} alt='' />
                    </div>
                </div>}
                {webdev && <div className='brands'>
                    <div className='brands-item'>
                        <img src={bfu} alt='' />
                    </div>
                    <div className='brands-item'>
                        <img src={avvila} alt='' />
                    </div>
                    <div className='brands-item'>
                        <img src={opak} alt='' />
                    </div>
                    <div className='brands-item'>
                        <img src={studyAbroad} alt='' />
                    </div>
                </div>}
            </div>
        </div>
    )
}