import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function FbInstaAds() {
      const questionObject = {
              first: {
                  question: 'What social platforms are best for my business?',
                  answer: 'Essentially, all platforms are good but not great for your brand. Choosing the right social media platform to advertise your products and services depends on several factors: products or service type//category, user demographics and consumer perference, marketing goals, and more.'
              },
              second: {
                  question: 'How do I know what kind of content to post on my social media pages?',
                  answer: 'As a brand, you not only want to drive online visibility, but you also want to maintain a good reputation. However, you can still remain personable and relatable while at it. Keep your content authentic, entertaining, inspiring and educating to continually engage your audience.'
              },
              third: {
                  question: 'Can I use the same content across several social media platforms?',
                  answer: 'Yes! Not only does this give a sense of uniformity to your effors at creating a digital persona, but it also shows a sense of coherence in your activities. However, when sharing content across platforms, ensure to optimize and repurpose it to suit the rules and best practices of each platform.'
              },
              fourth: {
                  question: 'How do I manage negative comments and reactions on my pages?',
                  answer: 'Always monitor your page to avoid delayed responses. A 24-hour lag may be telling your followers that there is no sense of urgency in your business ethics. Avoid nast exchanges of words (remember, there\'s never a time to be unprofessional in business). Also, keep it personal when you have to make the audience know you understand their concerns and are working to allay their fears.'
              },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
                services={SERVICES.FbInstaAds.TopCaption.services}
                service_name={SERVICES.FbInstaAds.TopCaption.service_name}
                service_bg_class={SERVICES.FbInstaAds.TopCaption.service_bg_class}
                subtitle={SERVICES.FbInstaAds.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.FbInstaAds.ServiceFeatures.content_title1}
                description1={SERVICES.FbInstaAds.ServiceFeatures.description1}
                img1={SERVICES.FbInstaAds.ServiceFeatures.img1}
                content_title2={SERVICES.FbInstaAds.ServiceFeatures.content_title2}
                description2={SERVICES.FbInstaAds.ServiceFeatures.description2}
                img2={SERVICES.FbInstaAds.ServiceFeatures.img2}
                content_title3={SERVICES.FbInstaAds.ServiceFeatures.content_title3}
                description3={SERVICES.FbInstaAds.ServiceFeatures.description3}
                img3={SERVICES.FbInstaAds.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}