import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function BrandingNPackaging() {
     const questionObject = {
             first: {
                 question: 'I\'ve got a logo, what more do I need?',
                 answer: 'The truth is that a brand is much more than a logo. A brand is a complete, wholesome visual representation that tells everthing worth saying about your business'
             },
             second: {
                 question: 'What does branding do for my business?',
                 answer: 'Branding makes your purpose resonate with your audience. It also gives potential customers an idea of your unique selling point and what to expect when consuming your product or service.'
             },
             third: { 
                 question: 'What is a brand?',
                 answer: 'A brand is essentially the persnality and identity of your business. It is a full respresentation of what you do and who you are. It helps the customers to form their very first impression of your business.'
             },
             fourth: {
                 question: 'Why is branding important?',
                 answer: 'Branding positions your company or product in the market and helps potential users to identify your uniqueness as a product or service provider among many others.'
             },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
                 services={SERVICES.BrandingNPackaging.TopCaption.services}
                service_name={SERVICES.BrandingNPackaging.TopCaption.service_name}
                service_bg_class={SERVICES.BrandingNPackaging.TopCaption.service_bg_class}
                subtitle={SERVICES.BrandingNPackaging.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.BrandingNPackaging.ServiceFeatures.content_title1}
                description1={SERVICES.BrandingNPackaging.ServiceFeatures.description1}
                img1={SERVICES.BrandingNPackaging.ServiceFeatures.img1}
                content_title2={SERVICES.BrandingNPackaging.ServiceFeatures.content_title2}
                description2={SERVICES.BrandingNPackaging.ServiceFeatures.description2}
                img2={SERVICES.BrandingNPackaging.ServiceFeatures.img2}
                content_title3={SERVICES.BrandingNPackaging.ServiceFeatures.content_title3}
                description3={SERVICES.BrandingNPackaging.ServiceFeatures.description3}
                img3={SERVICES.BrandingNPackaging.ServiceFeatures.img3} />
            <Testimonials />
             <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}