import React from 'react'
import '../css/testimonials.css'
import TestimonialCard from './TestimonialCard'
import UnderBar from './UnderBar'
import bfu from '../images/bfu_brand_logo.png'
import opak from '../images/opak_brand_logo.png'

export default function Testimonials() {
    return (
        <div className='testimonials-wrapper'>
            <div className='container testimonials-inner'>
                <div className='testimonials-header'>
                    <p className='title'>Testimonials</p>
                    <UnderBar bg_class='bg-orange' />
                    <p className='subtitle'>What our clients have to say about us </p>
                </div>
                <div className='testimonials'>
                    <TestimonialCard image={bfu} title='Buy From USA' description='They helped with our website and other branding materials, and we were really impressed with what they delivered. ' />
                    <TestimonialCard image={opak} title='Opak Homes' description='Swiftbranding NG helped us to create a logo, letterheads, business cards and a brochure and I kept getting lots of good compliments about them. ' />
                </div>
            </div>
        </div>
    )
}