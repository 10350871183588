import React from 'react'

import '../css/service_page.css'

import quote_right from '../images/quote_right.jpg'
import quote_left from '../images/quote_left.jpg'

import UnderBar from '../components/UnderBar'

export default function ServiceFeatures({features_caption, content_title1, content_title2, content_title3, description1, description2, description3, img1, img2, img3}) {
    return(
        <div className='service-features-wrapper'>
            <div className='title'>
                <h3>Features</h3>
                <UnderBar bg_class='bg-orange' />
            </div>
            <div className='container service-features-inner'>
                <div className='feature-item-wrapper'>
                    <div className='feature-item-lg'>
                        <div className='feature-content-img'>
                            <img src={img1} alt='' />
                        </div>
                        <div className='feature-content-text'>
                            <div className='bar'></div>
                            <h3>{content_title1}</h3>
                            <p>{description1}</p>
                        </div>
                    </div>
                    <div className='feature-item-mb'>
                        <div className='feature-content-img'>
                            <img src={img1} alt='' />
                        </div>
                        <div className='feature-content-text'>
                            <h3>{content_title1}</h3>
                            <div className='bar'></div>
                            <p>{description1}</p>
                        </div>
                    </div>
                </div>
                <div className='feature-item-wrapper'>
                    <div className='feature-item-lg'>
                        <div className='feature-content-img'>
                            <img src={img2} alt='' />
                        </div>
                        <div className='feature-content-text'>
                            <div className='bar'></div>
                            <h3>{content_title2}</h3>
                            <p>{description2}</p>
                        </div>
                    </div>
                    <div className='feature-item-mb'>
                        <div className='feature-content-img'>
                            <img src={img2} alt='' />
                        </div>
                        <div className='feature-content-text'>
                            <h3>{content_title2}</h3>
                            <div className='bar'></div>
                            <p>{description2}</p>
                        </div>
                    </div>
                </div>
                <div className='feature-item-wrapper'>
                    <div className='feature-item-lg'>
                        <div className='feature-content-img'>
                            <img src={img3} alt='' />
                        </div>
                        <div className='feature-content-text'>
                            <div className='bar'></div>
                            <h3>{content_title3}</h3>
                            <p>{description3}</p>
                        </div>
                    </div>
                    <div className='feature-item-mb'>
                        <div className='feature-content-img'>
                            <img src={img3} alt='' />
                        </div>
                        <div className='feature-content-text'>
                            <h3>{content_title3}</h3>
                            <div className='bar'></div>
                            <p>{description3}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='features-slide-flex slide-flex-one'>
                <p>For the average Joe, social media refers to the platforms through which they connect to friends and family, keep up with acquaintances, and share memories and experiences. However, for entrepreneurs and small business owners, this is an opportunity to boost public awareness. We understand how social media works. Let’s make it grow your business.</p>
                <a href='/free-consultation'><button type='button'>Get Free Consultation</button></a>
            </div>

            <div className='container features-slide-quote'>
                <div className='slide-left'>
                    <h3>Reach the Largest Audience in Social Media</h3>
                    <p>As of July 2020, according to DataReportal, the global statistic of social media users is estimated to be 3.96 billion. That’s more than half the world population! This means that you can increase brand visibility, secure valuable leads, and drive traffic to your website through targeted social media marketing.</p>
                </div>
                <div className='slide-right'>
                    <img src={quote_right} alt='' />
                </div>
            </div>

            <div className='features-slide-flex slide-flex-two'>
                <p>We build and launch advertising campaigns that tell your brand’s story and capture the attention of potential customers while at it. With eye-catching copy and distinct visual representation, your brand message will strike the right chords in the minds of social media users, causing them to take profitable action.</p>
                <a href='/free-consultation'><button type='button'>Get Free Consultation</button></a>
            </div>

            <div className='container features-slide-quote'>
                <div className='slide-left'>
                    <img src={quote_left} alt='' />
                </div>
                <div className='slide-right'>
                    <h3>Our experts can optimize your campaign for live events.</h3>
                    <p>Social media marketing is not limited to products and services. You can also amplify your brand message through event marketing. According to Aventri, 95% of marketers and 87% of C-suite executives agree that live events are an effective way to form connections with potential customers in an increasingly digital world.</p>
                </div>
            </div>
        </div>
    )
}