import React from 'react'
import '../css/caption.css'

export default function Caption({webdev, title, subtitle, _class, children}) {
    return (
        <div className={`caption-wrapper ${_class}`}>
            <div className='container caption-inner'>
                <div className='caption-content-left'>
                    <h2>{title.split('*')[0]}<br />{title.split('*')[1]}</h2>
                    <p>{subtitle.split('*')[0]}<br />{subtitle.split('*')[1]}</p>
                    {!webdev && <a href='/free-consultation'><button type='button'>Get Free Consultation</button></a>}
                    {webdev && <button className="btn nav-button mb" type="button" data-toggle='modal' data-target='#exampleModal'>
                        Request a Call
                    </button>}
                </div>
                <div className='caption-content-right lg'>
                    {children}
                </div>
            </div>
        </div>
    )
}