import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function EmailMarketing() {
    const questionObject = {
                  first: {
                          question: 'Can I buy email list?',
                          answer: 'Absolutely not! and here is why: No one wants to be bugged by emails they did not sign up for (even when they do, they may unsubscribe if it get too frequent). Your goals should be to find the right prospects and customers, so quality over quantity. Otherwise, you just might end up in their spam folder, or worse still blacklisted!'
                      },
                      second: {
                          question: 'How can I grow my email list?',
                          answer: 'Successful email marketing begings with having the right data. From past, current, to prospective customers, engaging an audience that is interested in what your organization has to offer makes the work easier. Sharable content and pop-ups that allow quick sign-ups will help amplify your reach.'
                      },
                      third: {
                          question: 'What Type Of Content Works Best?',
                          answer: 'While many people think of email marketing as strickly textual, you can incorporate videos, images, gifs, and many other awesome features in your marketing campaigns. More importantly, keeping your readers interested relies on the clarity of your message.'
                      },
                      fourth: {
                          question: 'What\'s The Best Time And Day To Send Emails?',
                          answer: 'While there\'s no be-all, end-all answer, you want to  try our different days and monitor audience activity like open rates to understand how best to serve your content. Also your audience\'s preferences are best known to them. So ask!'
                      },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
               services={SERVICES.EmailMarketing.TopCaption.services}
                service_name={SERVICES.EmailMarketing.TopCaption.service_name}
                service_bg_class={SERVICES.EmailMarketing.TopCaption.service_bg_class}
                subtitle={SERVICES.EmailMarketing.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.EmailMarketing.ServiceFeatures.content_title1}
                description1={SERVICES.EmailMarketing.ServiceFeatures.description1}
                img1={SERVICES.EmailMarketing.ServiceFeatures.img1}
                content_title2={SERVICES.EmailMarketing.ServiceFeatures.content_title2}
                description2={SERVICES.EmailMarketing.ServiceFeatures.description2}
                img2={SERVICES.EmailMarketing.ServiceFeatures.img2}
                content_title3={SERVICES.EmailMarketing.ServiceFeatures.content_title3}
                description3={SERVICES.EmailMarketing.ServiceFeatures.description3}
                img3={SERVICES.EmailMarketing.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}