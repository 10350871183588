import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import ContactForm from '../../components/ContactForm'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function SEO() {
      const questionObject = {
        first: {
                question: 'What is link building?',
                answer: 'Link building is a strategy that helps you acquire links to boost your page\'s link equity and subsequently the traffic and ranking.'
            },
            second: {
                question: 'What is keyword research?',
                answer: 'Through the help of keyword research , you can determine the keywords for which you should optimize the current and future pages of your website. It helps you determine what search queries internet users are entering to search engines so you can publish pages that have relevant words.'
            },
            third: {
                question: 'What Type Of Content Works Best?',
                answer: 'Anchor texts are clickable text in a hyperlink. They are elements on a website that match the target keyword of the page to which it links. Anchor text informs the crawling search engine about the topic of the linked-to page.'
            },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
                services={SERVICES.SEO.TopCaption.services}
                service_name={SERVICES.SEO.TopCaption.service_name}
                service_bg_class={SERVICES.SEO.TopCaption.service_bg_class}
                subtitle={SERVICES.SEO.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.SEO.ServiceFeatures.content_title1}
                description1={SERVICES.SEO.ServiceFeatures.description1}
                img1={SERVICES.SEO.ServiceFeatures.img1}
                content_title2={SERVICES.SEO.ServiceFeatures.content_title2}
                description2={SERVICES.SEO.ServiceFeatures.description2}
                img2={SERVICES.SEO.ServiceFeatures.img2}
                content_title3={SERVICES.SEO.ServiceFeatures.content_title3}
                description3={SERVICES.SEO.ServiceFeatures.description3}
                img3={SERVICES.SEO.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}