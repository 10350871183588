import React from 'react'
import '../css/testimonials.css'

export default function TestimonialCard({image, title, subtitle, description}) {
    return (
        <div className='testimonial-card'>
            <div className='avatar-wrapper'>
                <img src={image} alt='' />
            </div>
            <p className='title'>{title}</p>
            {subtitle && <p className='subtitle'>{subtitle}</p>}
            <p className='description'>{description}</p>
        </div>
    )
}