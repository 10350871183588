import React from 'react'
import Header from '../components/Header'
import TopCaption from '../components/TopCaption'
import Mission from '../components/Mission'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import Testimonials from '../components/Testimonials'

export default function About() {
    return (
        <React.Fragment>
            <Header />
            <TopCaption about={true} />
            <Mission />
            <Testimonials />
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
    
}