import React, { useEffect } from 'react'
import Header from '../components/Header'
import Caption from '../components/Caption'
import Brands from '../components/Brands'
import Carousel from '../components/Carousel'
import Testimonials from '../components/Testimonials'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import SubCaption from '../components/SubCaption'
import '../css/subcaption.css'
import landing_background from '../images/landing_background.jpg'
import img1 from '../images/sub_caption_img.png'
import img2 from '../images/sub_caption_img_2.png'
import UnderBar from '../components/UnderBar'

export default function Home() {
    useEffect(() => {
        [landing_background, img1, img2].forEach((picture) => {
            const img = new Image();
            img.src = picture;
            window[picture] = img;
        }); // force browser to prefetch required images
    // eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Header />
            <Caption title={`Attract the audience*that's looking for you`} subtitle={`Let's help you communicate the most*important features about your business`} _class='main-caption' />
            <Brands landing={true} />
            <SubCaption>
                <div className='sub-caption-left'>
                    <div className='title'>
                        <h2>What we can offer</h2>
                        <UnderBar bg_class='bg-light' />
                    </div>
                    
                    <p className='description'>Swift Branding serves individual and corporate entities by creating a wholesome brand identity that leaves a lasting impression on the targeted audience.<br /><br />We develop or rebuild brand experiences that are easily distinguishable for both old and new businesses.</p>
                    <a href='/about-us'><button type='button'>Learn more</button></a>
                </div>
                <div className='sub-caption-right'>
                    <img src={img1} alt='' />
                </div>
            </SubCaption>
            <Carousel />
            <Testimonials />
            <SubCaption>
                <div className='sub-caption-left'>
                    <img src={img2} alt='' />
                </div>
                <div className='sub-caption-right'>
                    <div className='title'>
                        <h2>Why we are efficient</h2>
                        <UnderBar bg_class='bg-light' />
                    </div>
                    <p className='description'>At Swift Branding, we understand the power of positive brand reputation and so, our work is characterized by the core values of trust, customer service, and success-driven results.  We also never underestimate the importance of strategy in bringing your objectives to life.</p>
                    <a href='/about-us'><button type='button'>Learn more</button></a>
                </div>
            </SubCaption>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}