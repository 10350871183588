import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

import '../css/thankyou.css'

export default function ThankYou() {
    return (
        <React.Fragment>
            <Header />
            <div className='thankyou-wrapper'>
                <div className='thankyou-inner'>
                    <h1>Thank you for contacting Swiftbranding.ng</h1>
                    <p>We appreciate your response and will get back to you shortly.<br />Cheers!</p>
                    <a href='/'>{'< '}Go back home</a>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}