import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function MobileDev() {
      const questionObject = {
         first: {
                 question: 'What platform is best to launch the application: Android or iOS?',
                 answer: 'We recommend you launch the application in Andriod as well as iOS platform to enable you to enjoy a wider reach. You may also want to consider a specialized tablet and iPad version of the app.'
             },
             second: {
                 question: 'Do you give clients an estimate of the project cost?',
                 answer: 'Yes, after consultation, we will provide you with an estimated figure based on the functions, features, and specifications your project requires.'
             },
             third: {
                 question: 'Will new OS releases in both iOS and Andriod affect my app?',
                 answer: 'With every OS release, you need to test your app to determine any potential deficiencies working. Once this is done, reach out to our development team to devise quick solutions. Both Apple & Google spread solutions for functions they are deprecating.'
          },
          fourth: {
              question: 'Can I make changes to may app after it\'s been launched?',
              answer:'Yes, we highly suggest that you initiate your project by launching a Minimum value Product (the simplest version of your app). After this, improvements can be added over time.'
             }
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
               services={SERVICES.MobileDev.TopCaption.services}
                service_name={SERVICES.MobileDev.TopCaption.service_name}
                service_bg_class={SERVICES.MobileDev.TopCaption.service_bg_class}
                subtitle={SERVICES.MobileDev.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.MobileDev.ServiceFeatures.content_title1}
                description1={SERVICES.MobileDev.ServiceFeatures.description1}
                img1={SERVICES.MobileDev.ServiceFeatures.img1}
                content_title2={SERVICES.MobileDev.ServiceFeatures.content_title2}
                description2={SERVICES.MobileDev.ServiceFeatures.description2}
                img2={SERVICES.MobileDev.ServiceFeatures.img2}
                content_title3={SERVICES.MobileDev.ServiceFeatures.content_title3}
                description3={SERVICES.MobileDev.ServiceFeatures.description3}
                img3={SERVICES.MobileDev.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}