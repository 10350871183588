import React from 'react'
import '../css/services.css'

export default function Services ({children}) {
    return (
        <div className='services-wrapper'>
            <div className='container services-inner'>
                <div className='cards'>
                    {children}
                </div>
            </div>
        </div>
    )
}