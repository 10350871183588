import React from 'react'
import '../css/works.css'

export default function WorkItem({children}) {
    return (
        <div className='work-item'>
            <div className='container work-item-inner'>
                {children}
            </div>
        </div>
    )
}