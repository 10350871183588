import React from 'react'
import '../css/carousel.css'
import '../css/services.css'
import Services from '../components/Services'
import ServiceCard from '../components/ServiceCard'
import UnderBar from './UnderBar'

export default function Carousel() {
      
  return (
    <div id="carouselExampleIndicators" className="carousel slide carousel-wrapper" data-ride="carousel">
      <div className='carousel-title'>
        <h3>Our Services</h3>
        <UnderBar bg_class='bg-orange' />
      </div>
      
      <div className="container carousel-inner">
        <div className="carousel-item active">
          <Services>
            <ServiceCard bg_class='web-dev' title='Web Development' link='/services/web-development' />
            <ServiceCard bg_class='seo' title='Search Engine Optimization' link='/services/search-engine-optimization' />
            <ServiceCard bg_class='branding-and-packaging' title='Product Branding &amp; Packaging' link='/services/product-branding-and-packaging' />
            <ServiceCard bg_class='fb-and-insta-ads' title='Facebook &amp; Instagram Ads' link='/services/facebook-and-instagram-ads' />
          </Services>
        </div>
        <div className="carousel-item">
        <Services>
            <ServiceCard bg_class='mobile-dev' title='Mobile App Development' link='/services/mobile-app-development' />
            <ServiceCard bg_class='social-media-marketing' title='Social Media Marketing' link='/services/social-media-marketing' />
            <ServiceCard bg_class='content-marketing' title='Content Marketing' link='/services/content-marketing' />
            <ServiceCard bg_class='email-marketing' title='Email Marketing' link='email-marketing' />
          </Services>
        </div>
      </div>
      <ol className="carousel-indicators indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          className="active indicator"
        ></li>
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="1"
          className="indicator"
        ></li>
      </ol>
    </div>
  );
}