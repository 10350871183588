import market_img_1 from '../../images/svg/Content Marketing Strategy.svg'
import market_img_2 from '../../images/svg/Content development.svg'
import market_img_3 from '../../images/svg/Copywriting.svg'
import BrandingNPackaging_img_1 from '../../images/svg/Product branding.svg'
import BrandingNPackaging_img_2 from '../../images/svg/Logo Design.svg'
import BrandingNPackaging_img_3 from '../../images/svg/Packaging design.svg'
import email_marketing_img_1 from '../../images/svg/Product branding.svg'
import email_marketing_img_2 from '../../images/svg/Email funnels.svg'
import email_marketing_img_3 from '../../images/svg/Email newsletter.svg'
import facebook_instagram_img_1 from '../../images/svg/Ads copywriting.svg'
import facebook_instagram_img_2 from '../../images/svg/Performance tracking  and optimization.svg'
import facebook_instagram_img_3 from '../../images/svg/Ad management.svg'
import MobileDev_img_1 from '../../images/svg/App design and strategy.svg'
import MobileDev_img_2 from '../../images/svg/Analysis and  developmental planning.svg'
import MobileDev_img_3 from '../../images/svg/UI_UX DESIGN.svg'
import SocialMediaMarketing_img_1 from '../../images/svg/Customized content  development.svg'
import SocialMediaMarketing_img_2 from '../../images/svg/Social Media Advertising.svg'
import SocialMediaMarketing_img_3 from '../../images/svg/Social Media  Management.svg'
import SEO_img_1 from '../../images/svg/Backlink generation.svg'
import SEO_img_2 from '../../images/svg/On-page optimization.svg'
import SEO_img_3 from '../../images/svg/Off-page optimization.svg'
import WebDev_img_1 from '../../images/svg/Web design.svg'
import WebDev_img_2 from '../../images/svg/Web Content  Development.svg'
import WebDev_img_3 from '../../images/svg/Web Development.svg'

const TEXT__CONTENT__DATA = {
  ContentMarketing: {

    TopCaption:{
  services : true,
  service_name : 'Content*Marketing',
  service_bg_class : 'content-marketing',
  subtitle: 'Our content marketing service will secure*exponential growth to your web traffic.',
    },
    ServiceFeatures: {
       content_title1 : 'Content Marketing Strategy',
       description1 : 'We know that having a plan is key to getting results. That’s why we start by researching and working with the most promising options for your brand.',
       img1 :market_img_1,
       content_title2 : 'Content Development',
       description2 : 'Our team of content writers are skilled to transform raw ideas into standardized and appealing content.',
       img2 : market_img_2,
       content_title3 : 'Copywriting',
       description3 : 'We know how to highlight the strong points of your brand with the right words.​',
       img3 :market_img_3
       
    }
  },
  BrandingNPackaging: {

    TopCaption: {
      services: true,
      service_name: 'Product Branding*& Design',
      service_bg_class: 'branding-and-packaging',
      subtitle: 'Our Branding service helps you answer one important*question: ‘What do I want my business to be known for?’',
    },
    ServiceFeatures: {
      content_title1: 'Brand Strategy',
      description1: 'We understand how to build a definite brand that sets your products and services apart from the multitude within the market.',
      img1: BrandingNPackaging_img_1,
      content_title2: 'Logo Design',
      description2: 'Let’s help you leave a lasting impression on your present and prospective customers with logos that are simple and timeless yet versatile.',
      img2: BrandingNPackaging_img_2,
      content_title3: 'Packaging design',
      description3: 'Our design team is poised to help transform your product package into something that is easily recognizable and strikes a positive chord with your customers.',
      img3: BrandingNPackaging_img_3

    }
  },
  EmailMarketing: {

    TopCaption: {
      services: true,
      service_name: 'Email*Marketing',
      service_bg_class: 'email-marketing',
      subtitle: 'With our email marketing services, your audience will remain*engaged, informed and updated on brand information.',
    },
    ServiceFeatures: {
      content_title1: 'Product branding',
      description1: 'We understand how to build a definite brand that sets your products and services apart from the multitude within the market.',
      img1: email_marketing_img_1,
      content_title2: 'Email funnels',
      description2: ' Whether it’s building a community of subscribers or pitching your latest line of products, we know how to convert your audience every step of the way.',
      img2: email_marketing_img_2,
      content_title3: 'Email newsletter',
      description3: 'We’ll build your brand awareness, promote your brand recognition and keep communication lines with your audience open.​​',
      img3: email_marketing_img_3

    }
  },
  FbInstaAds: {

    TopCaption: {
      services: true,
      service_name: 'Facebook &*Instagram Ads',
      service_bg_class: 'fb-and-insta-ads',
      subtitle: 'Reach millions across the world in the most affordable and*effective waythrough targeted Facebook and Instagram ads.',
    },
    ServiceFeatures: {
      content_title1: 'Objective-based ad campaigns',
      description1: 'Launch adverts that speak to the end-goal that you have in mind. Be it brand awareness, increased reach or lead generation, Facebook ads offer a range of 11 objectives that sum up different business growth needs.',
      img1: facebook_instagram_img_1,
      content_title2: 'Performance tracking and optimization',
      description2: 'Our content development expertise will improve your brand’s reputation with thoroughly reviewed content and connect your brand to your audience.',
      img2: facebook_instagram_img_2,
      content_title3: 'Audience-targeted ads',
      description3: 'With Facebook and Instagram ads, you can choose your audience on several criteria so that your ad reaches the most valuable potential customers.',
      img3: facebook_instagram_img_3

    }
  },
   MobileDev: {

    TopCaption: {
      services: true,
      service_name: 'Mobile App*Development',
      service_bg_class: 'mobile-dev',
      subtitle: 'Build a personalized user experience for your customer*base with our mobile app development services.',
    },
    ServiceFeatures: {
      content_title1: 'App design and strategy',
      description1: 'Before we get down to building the ideal app for your brand, we research the standard and engage in competitor-watching to understand what matters the most and why.',
      img1: MobileDev_img_1,
      content_title2: 'Analysis and developmental planning',
      description2: 'Our team of developers know how to create customer-centred digital products that serve the intended goals and objectives even at the minimum-viable-product (MVP) phase.',
      img2: MobileDev_img_2,
      content_title3: 'UI/UX Design',
      description3: 'We know how to create finished products that are not just appealing but seamless in functionality, interaction and user experience.',
      img3: MobileDev_img_3
    }
  },
   SocialMediaMarketing: {

    TopCaption: {
      services: true,
      service_name: 'Social Media*Marketing',
      service_bg_class: 'social-media-marketing',
      subtitle: 'Give your business the leverage of public attention*with effective social media marketing services.',
    },
    ServiceFeatures: {
      content_title1: 'Ads Copywriting',
      description1: 'We will write sales copy that engages the public, persuades your audience and converts them to customers.',
      img1: SocialMediaMarketing_img_1,
      content_title2: 'Social Media Advertising',
      description2: 'Let’s help you grow your reach by connecting you to millions around the globe who actively use social media daily.',
      img2: SocialMediaMarketing_img_2,
      content_title3: 'Social Media Management',
      description3: 'Our social media team understands the art of sustaining engagement, selling on social media to drive business growth, and building your community of target audience.',
      img3: SocialMediaMarketing_img_3
    }
  },
   SEO: {

    TopCaption: {
      services: true,
      service_name: 'Search Engine*Optimization',
      service_bg_class: 'seo',
      subtitle: 'Our SEO service will boost your website traffic organically*(without paid ads) and get more visibility for your brand.',
    },
    ServiceFeatures: {
      content_title1: 'Backlink generation',
      description1: 'We will increase your site’s visibility in search engine algorithms by securing reputable backlinks in related niches.',
      img1: SEO_img_1,
      content_title2: 'On-page optimization',
      description2: 'Our SEO team will enhance your website’s rankings through researched and relevant content, and other SEO elements.',
      img2: SEO_img_2,
      content_title3: 'Off-page optimization',
      description3: 'We will engage in off-page SEO activities that promote your site and catalyze your visibility in SERPs.',
      img3: SEO_img_3
    }
  },
   WebDev: {

    TopCaption: {
      services: true,
      service_name: 'Web*Development',
      service_bg_class: 'web-dev',
      subtitle: 'We ideate, design and build websites that*give you visibility before the potential audience.',
    },
    ServiceFeatures: {
      content_title1: 'Web design',
      description1: 'Our team of web designers are skilled to create websites that have all the essential elements and are appealing to the eye.',
      img1: WebDev_img_1,
      content_title2: 'Web Content Development',
      description2: 'We build effective web content that guides your audience through your website with clarity.',
      img2: WebDev_img_2,
      content_title3: 'Web Development',
      description3: 'We simplify your journey to running your own website with our web development service that helps you create your own ‘online residence’ with the relevant functions.',
      img3: WebDev_img_3
    }
  },
}
export default TEXT__CONTENT__DATA;