import React, { useEffect } from 'react'
import '../css/mission.css'
import mission_img from '../images/mission_img.jpg'

export default function Mission() {
    useEffect(() => {
        [mission_img].forEach((picture) => {
            const img = new Image();
            img.src = picture;
            window[picture] = img;
        }); // force browser to prefetch required images
    // eslint-disable-next-line
    }, [])
    return (
        <div className='mission-wrapper'>
            <div className='container mission-inner'>
                <div className='mission-left'>
                    <div className='bar'></div>
                    <h3 className='title'>Our Mission</h3>
                    <p className='description'>Our mission is to help our clients convey their brand message coherently and successfully in a way that resonates with the audience and sets the brand apart from the competition.<br /><br />We are on a journey to helping brands better position themselves before its customers, communicate their objectives and build great partnerships while at it.<br /><br />Our customers enjoy a vantage point that ensures their brand is attractive to and resonates with its intended audience. We pride ourselves on research-based processes and design strategy that speaks to what your brand fundamentally represents.</p>
                </div>
                <div className='mission-right lg'>
                    <img src={mission_img} alt='' />
                </div>
            </div>
        </div>
    )
}