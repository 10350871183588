
import React, { useEffect } from "react"
import logo from "../images/logo.svg"
import "../css/header.css"
import arrow_down from '../images/arrow_down.svg'
import Modal from "./Modal";

const Header = () => {
  const urlArray = window.location.href.split('/')
  const current = window.location.href.split('/').pop() // get the current page title
    useEffect(() => {
        if (current === "" || current === null) {
          document.getElementById('home').style.color = '#fc4f15'
        } else if(current !== "#contact-us" && !urlArray.includes('services') && current !== 'free-consultation' && current !== 'web-development' && current !== 'thankyou') {
          document.getElementById(current).style.color = '#fc4f15'
        } else if (urlArray.includes('services')) {
          document.getElementById('services').style.color = '#fc4f15'
        } else if (current === "#contact-us") {
          document.getElementById('contact').style.color = '#fc4f15'
        }
        
    }) // use the value of "current" to set the accent color of the current page link

  return (
    <React.Fragment>
      <div className='header-wrapper sticky-top'>
        <Modal />
        <div className="container navbar navbar-expand-lg navbar-light">
        <button
            className="navbar-toggler hamburger"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img className="logo-header" src={logo} alt="logo" />
          </a>
          
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                
                  <a id='home' className="nav-a" href="/">
                    Home
                  </a>
                
              </li>
              <li className="nav-item">
                
                  <a id='about-us' className="nav-a" href="/about-us">
                    About Us
                  </a>
                
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-a"
                  href="?"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span id='services'>Services</span>
                  <img src={arrow_down} alt='' className='chevron' />
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="/services/content-marketing">
                    Content Marketing
                  </a>

                  {/* <a className="dropdown-item" href="/services/search-engine-marketing">
                    Search Engine Marketing
                  </a> */}

                  <a className="dropdown-item" href="/services/social-media-marketing">
                    Social Media Marketing
                  </a>

                  <a className="dropdown-item" href="/services/email-marketing">
                    Email Marketing
                  </a>

                  <div className="dropdown-divider"></div>

                  <a className="dropdown-item" href="/services/search-engine-optimization">
                    Search Engine Optimization
                  </a>

                  <a className="dropdown-item" href="/services/web-development">
                    Web Development
                  </a>

                  <a className="dropdown-item" href="/services/mobile-app-development">
                    Mobile App Development
                  </a>

                  <div className="dropdown-divider"></div>

                  {/* <a className="dropdown-item" href="/services/google-search-ads">
                    Google Search Ads
                  </a> */}

                  <a
                    className="dropdown-item"
                    href="/services/facebook-and-instagram-ads"
                  >
                    Facebook &amp; Instagram Ads
                  </a>

                  <div className="dropdown-divider"></div>

                  <a
                    className="dropdown-item"
                    href="/services/product-branding-and-design"
                  >
                    Product Branding &amp; Design
                  </a>
                </div>
              </li>
              <li className="nav-item">
                
                  <a id='our-works' className="nav-a" href="/our-works">
                    Our Works
                  </a>
                
              </li>
              <li className="nav-item">
                
                  <a id='contact' className="nav-a" href={window.location.href.split('/').includes('#contact-us') ? window.location.href : (window.location.href.split('/').pop() !== '' ? (window.location.href + '/#contact-us') : (window.location.href + '#contact-us'))}>
                    Contact Us
                  </a>
                
              </li>
              <li className='nav-item'>
                <button className="btn nav-button get-started-mb" type="button" data-toggle='modal' data-target='#exampleModal'>
                  Request a Call
                </button>
              </li>
            </ul>
          </div>{" "}
          {/* end navbar-collapse */}
          <div>
              <button className="btn nav-button get-started-lg" type="button" data-toggle='modal' data-target='#exampleModal'>
                Request a Call
              </button>
          </div> 
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
