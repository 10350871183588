import React, { 
    useEffect
} from 'react'
import Header from '../components/Header'
// import { ReCaptcha } from 'react-recaptcha-google'
import '../css/works.css'
import '../css/contact.css'
import '../css/caption.css'
import WorkItem from '../components/WorkItem'
import webdev_background from '../images/webdev_page_background.jpg'
import mac_avilla from '../images/mac_avvila.svg'
import mac_BFU from '../images/mac_BFU.svg'
import mac_geonel from '../images/mac_geonel.png'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import Brands from '../components/Brands'
import Caption from '../components/Caption'
import Testimonials from '../components/Testimonials'

export default function WebDevMain() {
    let current;
    // const captchaDemo = useRef()
    // const siteKey = '6Ld2_8sZAAAAAEEMPLErw9XrwCL_kvpWYWUso8lk'

    // function onLoadRecaptcha() {
    //     if (captchaDemo) {
    //         captchaDemo.current.reset();
    //         captchaDemo.current.execute();
    //     }
    // }
    // function verifyCallback(recaptchaToken) {
    //     // Here you will get the final recaptchaToken!!!  
    //     console.log(recaptchaToken, "<= your recaptcha token")
    // }
    if(window.location.href.split('/').pop() === '#contact-us') {
        current = 'web-development'
    } else {
        current = window.location.href.split('/').pop()
    }
    useEffect(() => {
        [webdev_background, mac_BFU, mac_avilla , mac_geonel].forEach((picture) => {
            const img = new Image();
            img.src = picture;
            window[picture] = img;
        }); // force browser to prefetch required images
        // if (captchaDemo) {
        //     console.log("started, just a second...")
        //     captchaDemo.current.reset();
        //     captchaDemo.current.execute();
        // }
    // eslint-disable-next-line
    }, [])
    function handleSubmit(e) {
        e.preventDefault()
        window.location.replace('/thankyou')
    }
    return (
        <React.Fragment>
            <Header />
            <Caption webdev={true} title='Web Development' subtitle='Here is a look of few examples of different websites*we’ve created for Startups.' _class='web-dev'>
                <form className='webdev-form' onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                        <p>Name</p>
                        <input type="text" minLength="2" id="name" name="name" placeholder="e.g. Adekunle Ciroma" required />
                    </div>
                    <div className="form-group">
                        <p>Email Address</p>
                        <input type="email" minLength="2" id="email" name="email" placeholder="e.g. ciroma@yopmail.com" required />
                    </div>
                    <div className="form-group">
                        <p>Phone Number</p>
                        <input type="text" minLength="2" id="phone" name="phone" placeholder="e.g. 08093786190" required />
                    </div>
                    <div className="form-group">
                        <p>Message</p>
                        <textarea type="text" minLength="2" id="message" name="message" placeholder="e.g. I need a rebrand for my business" required />
                    </div>
                    <div style={{height:'0.1rem', visibility: 'hidden'}}>
                        <input type="text" id="webdev" name="webdev" value={`${current}/Swiftbranding`} style={{height:'0.1rem'}} required readOnly />
                    </div>
                    {/* <ReCaptcha
                        ref={captchaDemo}
                        size="invisible"
                        render="explicit"
                        sitekey={siteKey}
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={verifyCallback}
                    /> */}
                    <div className="form-group consultation-btn-wrapper">
                        <button type="submit" className="btn btn-primary" >Request a call</button>
                    </div>
                </form>
            </Caption>
            <Brands webdev={true} />
            <WorkItem>
                <div className='work-item-left lg'>
                    <img src={mac_avilla} alt='' className='mac' />
                </div>
                <div className='work-item-right'>
                    <div className='bar'></div>
                    <h3 className='title'>Avvila Global</h3>
                    <p className='description'>Avvila Global is a family owned company with a passion for social entrepreneurship and sustainability.</p>
                </div>
                <div className='work-item-left mb'>
                    <img src={mac_avilla} alt='' className='mac' />
                </div>
            </WorkItem>
            <WorkItem>
                <div className='work-item-left'>
                <div className='bar'></div>
                    <h3 className='title'>Buy From USA</h3>
                    <p className='description'>Buy from USA facilitates fast, secure and efficient purchases from the United States of America to Nigeria. They provide customers with cost-effective international shopping experience with budget friendly deals.</p>
                </div>
                <div className='work-item-right'>
                    <img src={mac_BFU} alt='' className='mac' />
                </div>
            </WorkItem>
            <WorkItem>
                <div className='work-item-left lg'>
                    <img src={mac_geonel} alt='' className='mac' />
                </div>
                <div className='work-item-right'>
                    <div className='bar'></div>
                    <h3 className='title'>Geonel</h3>
                    <p className='description'>Buy from USA facilitates fast, secure and efficient purchases from the United States of America to Nigeria. They provide customers with cost-effective international shopping experience with budget friendly deals.</p>
                </div>
                <div className='work-item-left mb'>
                    <img src={mac_geonel} alt='' className='mac' />
                </div>
            </WorkItem>
            <Testimonials />
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}