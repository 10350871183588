import React, { useEffect } from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import { loadReCaptcha } from 'react-recaptcha-google'
import Home from './pages/Home';
import OurWorks from './pages/OurWorks';
import About from './pages/About';
import BrandingNPackaging from './pages/services/BrandingNPackaging'
import ContentMarketing from './pages/services/ContentMarketing';
import EmailMarketing from './pages/services/EmailMarketing';
import FbInstaAds from './pages/services/FbInstaAds';
import MobileDev from './pages/services/MobileDev';
import SEO from './pages/services/SEO';
import SocialMediaMarketing from './pages/services/SocialMediaMarketing';
import WebDev from './pages/services/WebDev';
import Consultation from './pages/Consultation';
import WebDevMain from './pages/WebDevMain';
import ThankYou from './pages/ThankYou';

function App() {
  useEffect(() => {
    loadReCaptcha();
    // eslint-disable-next-line
  }, [])
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/home' component={Home} />
          <Route exact path='/about-us' component={About} />
          <Route exact path='/our-works' component={OurWorks} />
          <Route exact path='/free-consultation' component={Consultation} />
          <Route exact path='/web-development' component={WebDevMain} />
          <Route exact path='/thankyou' component={ThankYou} />
          <Route exact path='/services/product-branding-and-design' component={BrandingNPackaging} />
          <Route exact path='/services/content-marketing' component={ContentMarketing} />
          <Route exact path='/services/email-marketing' component={EmailMarketing} />
          <Route exact path='/services/facebook-and-instagram-ads' component={FbInstaAds} />
          <Route exact path='/services/mobile-app-development' component={MobileDev} />
          <Route exact path='/services/search-engine-optimization' component={SEO} />
          <Route exact path='/services/social-media-marketing' component={SocialMediaMarketing} />
          <Route exact path='/services/web-development' component={WebDev} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
