import React from 'react';
import classes from './AccordionGenerator.module.css'
import Accordion from '../AccordionGenerator/Accordion/Accordion'

const AccordionGenerator = (props) => {

  const Accordions = Object.keys(props.questionObject).map(
    igKey => <Accordion
      question={props.questionObject[igKey].question}
      value={props.questionObject[igKey].answer}
      key={igKey}
    />
  )

  return (
    <div >
      <h4 className={classes.Title}>{props.children}</h4>
      <div className={classes.UnderBar} >
        <div className={classes.BgOrange}/>
      </div>
      <div className={classes.AccordionGenerator}>
        {Accordions}
      </div>
    </div>
  )
}
export default AccordionGenerator;