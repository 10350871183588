import React, { Component } from 'react';
import classes from './Accordion.module.css'

class Accordion extends Component{

  state = {
   questionAdded: false
  }

  addQuestionHandler = () => {
    this.setState(
      {questionAdded:!this.state.questionAdded}
    )
  }

  render() {
    return( <div className={classes.Accordion}>
      <p
        className={classes.AccordionPara}
        onClick={this.addQuestionHandler}
      >
        {this.props.question}
        <span
        className={classes.Toggle}
        >{this.state.questionAdded ? '-' : '+'} </span></p>
      {this.state.questionAdded ? <p
        className={classes.Answer}
      >{this.props.value}</p> : null}

    </div>
    )
  }
}

export default Accordion;