import React from 'react'
import '../css/services.css'
import forward from '../images/forward.svg'

export default function ServiceCard ({bg_class, title, link}) {
        
    return (
        <div className={`card-wrapper ${bg_class}`}>
            <div className='card-content-placeholder'>
                <h3 className='card-title'>
                    {title}
                </h3>
            </div>
            <div className='card-content' onMouseOver={(e) => {
                e.target.classList.add('show')
            }} onMouseLeave={(e) => {
                e.target.classList.remove('show')
            }}>
                <h3 className='card-title'>
                    {title}
                </h3>
                <div className='card-link'>
                    <a href={link}>Learn More <img src={forward} alt='' /></a>
                </div>
            </div>
        </div>
    )
}