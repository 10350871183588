import React from "react";
import "../css/footer.css";
import logo from "../images/logo_footer.svg";
import instagram_black from "../images/instagram_icon.svg";
// import twitter_black from "../images/twitter_icon.svg";
import facebook_black from "../images/facebook_icon.svg";
import mail_icon from '../images/mail_icon_footer.png'
import phone_icon from '../images/phone_icon_footer.png'

const Footer = () => {
  return (
    <div className="container-fluid footer-content-wrapper">
      <div className="row">
        <div className="col-4 col-sm-4 col-md-4">
          <img src={logo} alt="" className="logo-footer main" />
          <img src={logo} alt="" className="logo-footer mb" />
          <img src={logo} alt="" className="logo-footer tb" />
          <div className="image-group">
            <a href="https://instagram.com/swiftbrandingng" target="_blank" rel="noopener noreferrer">
              <img
                src={instagram_black}
                alt=""
                className="footer-icon"
              />
            </a>
            {/* <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
              <img src={twitter_black} alt="" className="footer-icon" />
            </a> */}
            <a href="https://facebook.com/swiftbrandingng" target="_blank" rel="noopener noreferrer">
              <img
                src={facebook_black}
                alt=""
                className="footer-icon fb-image"
                style={{marginhrefp: "0px", marginLeft: "0px"}}
              />
            </a>
          </div>
          <div className='copyright-text-wrapper'>
            <p className='copyright-text'>©copyright 2020 swiftbrand.inc.<br />All rights reserved.</p>
          </div>
          <div className='contact-details'>
            <p><a href='tel:+234 810 226 3874'><img src={phone_icon} alt='' />+234 810 226 3874</a></p>
            <p><a href='mailto:info@digifigs.com'><img src={mail_icon} alt='' />info@digifigs.com</a></p>
          </div>
        </div>
        <div className="col-8 col-sm-8 col-md-8">
          <div className="row">
            <div className="col-5 col-sm-4 col-md-3">
              <h6 className="ibadan">Navigation</h6>
              <a href="/">
                <h6 className="address">Home</h6>
              </a>
              <a href="/about-us">
                <h6 className="address">About us</h6>
              </a>
              <a href="/#contact-us">
                <h6 className="address">Contact us</h6>
              </a>
              <a href="/free-consultation">
                <h6 className="address">Consultation</h6>
              </a>
            </div>
            <div className="col-7 col-sm-4 col-md-3">
              
                <h6 className="ibadan">Services</h6>
              
              <a href="/services/product-branding-and-design">
                <h6 className="address">Product Branding &amp; Design</h6>
              </a>
              <a href="/services/content-marketing">
                <h6 className="address">Content Marketing</h6>
              </a>
              <a href="/services/email-marketing">
                <h6 className="address">Email Marketing</h6>
              </a>
              <a href="/services/social-media-marketing">
                <h6 className="address">Social Media Marketing</h6>
              </a>
              <a href="/services/facebook-and-instagram-ads">
                <h6 className="address">Facebook &amp; Instagram Ads</h6>
              </a>
              <a href="/services/mobile-app-development">
                <h6 className="address">Mobile App Development</h6>
              </a>
              <a href="/services/web-development">
                <h6 className="address">Website Development</h6>
              </a>
              <a href="/services/search-engine-optimization">
                <h6 className="address">Search Engine Optimisation </h6>
              </a>
            </div>
            {/*             <div className="col-4 col-sm-4 col-md-auhref col-space"></div>
             */}{" "}
            <div className="col-6 col-sm-4 col-md-3 case-studies-footer">
              <h6 className="ibadan">Our Works</h6>
              <a href="/our-works">
                <h6 className="address">Avvilia Global</h6>
              </a>
              <a href="/our-works">
                <h6 className="address">Buy From USA</h6>
              </a>
              <a href="/our-works">
                <h6 className="address">Geonel</h6>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row footer-bothrefm">
        <div className="col-sm-12 col-md-3">
          <div className="image-group">
            <a href="https://instagram.com/digifigs" target="_blank" rel="noopener noreferrer">
              <img src={instagram_black} alt="" className="footer-icon" />
            </a>
            <a href="https://twitter.com/digifigsinc" target="_blank" rel="noopener noreferrer">
              <img src={twitter_black} alt="" className="footer-icon" />
            </a>
            <a href="https://facebook.com/digifigs" target="_blank" rel="noopener noreferrer">
              <img src={facebook_black} alt="" className="footer-icon" style={{marginhrefp: "0px", marginLeft: "0px"}} />
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 copy">
          <p className="copy-txt">
            &copy; 2020 Copyright - Digifigs Limited | All Rights Reserved
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 sub">
          <p className="sub-txt">SUBSCRIBE href OUR NEWSLETTER</p>
          <form class="form-inline my-2 my-lg-0">
            <input
              class="form-control mr-sm-2 input-sub"
              type="email"
              placeholder="Your e-mail Address"
              aria-label="Email"
            />
            <buthrefn className="btn btn-primary btn-sub">
              <img className="arrow-sub" src={arrow} alt="" />
            </buthrefn>
          </form>
        </div>
        <div className="col-sm-12 col-md-12 copy-mb">
          <p className="copy-txt">
            &copy; 2020 Copyright - Digifigs Limited | All Rights Reserved
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
