import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import ContactForm from '../../components/ContactForm'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function SocialMediaMarketing() {
    const questionObject = {
        first: {
            question: 'Is social media for me?',
            answer: 'Yes, social media visibility:  can help all business type today because the internet is the most prominent platform of communication and interaction. You only need to discover what channel best suits your brand and has a large percentage of your potential audience'
        },
        second: {
            question: 'Do I need to already-active social media accounts?',
            answer: 'No, you don\'t need to have existing Facebook and Twitter accounts. However, we\'re happy to set them up for you and build the engagement thereon. And if you already do, we\'ll work with what you have and establish more online presence'
        },
        third: {
            question: 'How do I know the best social network for my brand?',
            answer: 'Our team can help you determine the best channels for your business based on your product type, potential audience persona, and much more'
        },
        fourth: {
            question: 'How will you know what content to post for my company?',
            answer: 'We\'ll research your industry trends and develop a strategy that will highlight your unique selling point and engage your customers'
        },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
                services={SERVICES.SocialMediaMarketing.TopCaption.services}
                service_name={SERVICES.SocialMediaMarketing.TopCaption.service_name}
                service_bg_class={SERVICES.SocialMediaMarketing.TopCaption.service_bg_class}
                subtitle={SERVICES.SocialMediaMarketing.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.SocialMediaMarketing.ServiceFeatures.content_title1}
                description1={SERVICES.SocialMediaMarketing.ServiceFeatures.description1}
                img1={SERVICES.SocialMediaMarketing.ServiceFeatures.img1}
                content_title2={SERVICES.SocialMediaMarketing.ServiceFeatures.content_title2}
                description2={SERVICES.SocialMediaMarketing.ServiceFeatures.description2}
                img2={SERVICES.SocialMediaMarketing.ServiceFeatures.img2}
                content_title3={SERVICES.SocialMediaMarketing.ServiceFeatures.content_title3}
                description3={SERVICES.SocialMediaMarketing.ServiceFeatures.description3}
                img3={SERVICES.SocialMediaMarketing.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}