import React from 'react'
import TopCaption from '../../components/TopCaption'
import Header from '../../components/Header'
import ServiceFeatures from '../../components/ServiceFeatures'
import SERVICES from '../services/ServicesTextContent'
import ContactForm from '../../components/ContactForm'
import AccordionGenerator from '../AccordionGenerator/AccordionGenerator'
import Footer from '../../components/Footer'
import Testimonials from '../../components/Testimonials'

export default function WebDev() {
      const questionObject = {
          first: {
              question: 'What is the cost of a website?',
              answer:'The cost of developing a website depends on the peculiarities and complexity of the project. We offer a free quote which estimates basic service and a consultation session to help us understand exactly what you want.'
        },
          second: {
              question: 'What\'s the firs step to creating a website to promote my small business?',
              answer:'Launching your website involves a series of processes from securing a domain name, to a web server that hosts your website and a professionally-develeoped website. You also need the website to be uploaded to your host and promoted to search engines. Our team of web design and development experts are avialable to help you with all aspects of getting a website up and running'
        },
          third: {
              question: 'What domain name should I choose?',
              answer:'It is advised that your domain name should be something be something easy, clear, and memorable enough to stick to your visitor\'s mind after the first encounter'
        },
    }
    return (
        <React.Fragment>
            <Header />
            <TopCaption
                services={SERVICES.WebDev.TopCaption.services}
                service_name={SERVICES.WebDev.TopCaption.service_name}
                service_bg_class={SERVICES.WebDev.TopCaption.service_bg_class}
                subtitle={SERVICES.WebDev.TopCaption.subtitle} />
            <ServiceFeatures
                content_title1={SERVICES.WebDev.ServiceFeatures.content_title1}
                description1={SERVICES.WebDev.ServiceFeatures.description1}
                img1={SERVICES.WebDev.ServiceFeatures.img1}
                content_title2={SERVICES.WebDev.ServiceFeatures.content_title2}
                description2={SERVICES.WebDev.ServiceFeatures.description2}
                img2={SERVICES.WebDev.ServiceFeatures.img2}
                content_title3={SERVICES.WebDev.ServiceFeatures.content_title3}
                description3={SERVICES.WebDev.ServiceFeatures.description3}
                img3={SERVICES.WebDev.ServiceFeatures.img3} />
            <Testimonials />
            <AccordionGenerator questionObject={questionObject}>
                Frequently Asked questions
            </AccordionGenerator>
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}