import React, { useEffect } from 'react'
import TopCaption from '../components/TopCaption'
import Header from '../components/Header'
import '../css/works.css'
import WorkItem from '../components/WorkItem'
import mac_avilla from '../images/mac_avvila.svg'
import mac_BFU from '../images/mac_BFU.svg'
import mac_geonel from '../images/mac_geonel.png'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import Testimonials from '../components/Testimonials'

export default function OurWorks() {
    useEffect(() => {
        [mac_BFU, mac_avilla, mac_geonel].forEach((picture) => {
            const img = new Image();
            img.src = picture;
            window[picture] = img;
        }); // force browser to prefetch required images
    // eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Header />
            <TopCaption works={true} />
            <WorkItem>
                <div className='work-item-left lg'>
                    <img src={mac_avilla} alt='' className='mac' />
                </div>
                <div className='work-item-right'>
                    <div className='bar'></div>
                    <h3 className='title'>Avvila Global</h3>
                    <p className='description'>Avvila Global is a family owned company with a passion for social entrepreneurship and sustainability.</p>
                </div>
                <div className='work-item-left mb'>
                    <img src={mac_avilla} alt='' className='mac' />
                </div>
            </WorkItem>
            <WorkItem>
                <div className='work-item-left'>
                <div className='bar'></div>
                    <h3 className='title'>Buy From USA</h3>
                    <p className='description'>Buy from USA facilitates fast, secure and efficient purchases from the United States of America to Nigeria. They provide customers with cost-effective international shopping experience with budget friendly deals.</p>
                </div>
                <div className='work-item-right'>
                    <img src={mac_BFU} alt='' className='mac' />
                </div>
            </WorkItem>
            <WorkItem>
                <div className='work-item-left lg'>
                    <img src={mac_geonel} alt='' className='mac' />
                </div>
                <div className='work-item-right'>
                    <div className='bar'></div>
                    <h3 className='title'>Geonel</h3>
                    <p className='description'>Buy from USA facilitates fast, secure and efficient purchases from the United States of America to Nigeria. They provide customers with cost-effective international shopping experience with budget friendly deals.</p>
                </div>
                <div className='work-item-left mb'>
                    <img src={mac_geonel} alt='' className='mac' />
                </div>
            </WorkItem>
            <Testimonials />
            <ContactForm />
            <Footer />
        </React.Fragment>
    )
}